<template>
    <div class="trait-shop-card" :class="{ 'sale': isSale }">
        <div class="trait-shop-card__category">{{ category }}</div>
        <div class="trait-shop-card__sale" v-if="isSale">
            <ISale width="60" />
        </div>
        <div class="trait-shop-card__image">
            <img :src="image" alt="image" width="300" height="300" @click.prevent="zoomImage" />
        </div>
        <div class="trait-shop-card__name">{{ name }}</div>
        <div class="trait-shop-card__price" :class="{ 'has-old': !!oldPrice }">
            <div class="trait-shop-card__price-title">PRICE</div>
            <div class="trait-shop-card__price-value">
                <div class="trait-shop-card__price-old" v-if="oldPrice"> <img class="trait-shop-card__price-icon"
                        src="@/assets/img/meth.png" alt="meth" width="14" /> {{ oldPrice }} METH</div>
                <div class="trait-shop-card__price-value-row" v-if="priceMeth > 0">
                    <img class="trait-shop-card__price-icon" src="@/assets/img/meth.png" alt="meth" width="16" />
                    {{ formatNumberSpaces(priceMeth) }} METH
                </div>
                <div class="trait-shop-card__price-value-del" v-if="priceFlags > 0 && priceMeth > 0">
                    <span>OR</span>
                </div>
                <div class="trait-shop-card__price-value-row" v-if="priceFlags > 0"
                    v-tooltip="{ content: `Achievement Flags. See Achievements page to claim Flags`, distance: 5, }">
                    <IFlag class="trait-shop-card__price-icon" width="16" />
                    {{ formatNumberSpaces(priceFlags) }} FLAGS
                </div>
            </div>
        </div>
        <div class="trait-shop-card__minted-count">
            <span>Minted</span>
            {{ mintedCount }} {{ maxQuantity > -1 ? '/' + maxQuantity : '' }}
        </div>
        <!-- <TraitShopCardTimer v-if="isTimer" :time="item.expiryDate" /> -->
        <TraitShopCardBundle :items="item.traits_out" v-if="isBundle" />
        <Transition name="t-trait-shop-slide" mode="out-in">
            <TraitShopCardCount v-if="showCount"
                :accept-text="activeAction === 'cart' ? 'Add to cart' : `Buy ${isBundle ? 'Set' : 'Trait'}`"
                :disabled="isLoading" :max-count="maxCount" @accept="acceptAction" @cancel="cancelAction" />
            <div v-else-if="activeAction === 'card'" class="trait-shop-card__actions-row">
                <TraitShopCardButton :item="item" />
            </div>
            <div v-else class="trait-shop-card__actions">
                <div class="trait-shop-card__sold-out" v-if="isSoldOut">Sold Out</div>
                <div class="trait-shop-card__coming-soon" v-else-if="isComingSoon">
                    Coming Soon
                    <div class="trait-shop-card__coming-soon-timer">
                        <template v-if="comingSoonTimer.days.value > 0">{{ comingSoonTimer.days }}d</template>
                        {{ comingSoonTimer.hours }}h {{ comingSoonTimer.minutes }}m {{ comingSoonTimer.seconds }}s
                    </div>
                </div>
                <div v-else-if="isLocked"
                    v-tooltip="{ content: `You need to own a Trait from ${communityName} in the wallet <br> to be able to buy this trait from The Shop`, distance: 10, html: true }"
                    class="trait-shop-card__locked" @click.prevent="onLockClick">
                    <div v-if="isInitingCollection" class="trait-shop-card__locked-loading">
                        <img class="loader" src="@/assets/img/spinner-clear.svg" width="50" />
                    </div>
                    <ILock width="36" />You should be holder of
                    <span>{{ communityName }}</span>
                </div>
                <!-- v-if="!isBuyCard" -->



                <a v-if="activeAction !== 'cart' && priceMeth > 0 && !isBuyCard && !isExpiring"
                    class="trait-shop-card__add-to-cart" href="#" @click.prevent="runAction('cart')">Add To Cart</a>
                <a v-if="isExpiring" href="javascript:void(0)" class="trait-shop-card__expiring"
                    v-tooltip="{ content: `After offer is expired, trait will no longer be available on The Shop.`, distance: 10, html: true }">Expires
                    in
                    <template v-if="expireTime.days.value > 0">{{ expireTime.days }}d</template>
                    {{ expireTime.hours }}h {{ expireTime.minutes }}m {{ expireTime.seconds }}s
                </a>

                <div class="trait-shop-card__actions-row">
                    <!-- <a
                        href="javascript:void(0)"
                        :class="{ active: isInWishlist }"
                        class="trait-shop-card__wishlist"
                        @click.prevent="addToWishlist"
                        v-tooltip="{ content: 'Add to Wishlist', distance: 10 }"
                    >
                        <IHeart width="15" />
                    </a>-->
                    <a href="javascript:void(0)" class="trait-shop-card__buy" @click.prevent="runAction('buy')">
                        <IBuy width="15" />
                        Buy {{ isBundle ? 'set' : 'trait' }}
                    </a>

                </div>
                <div class="trait-shop-card__actions-row" v-if="isBuyCard && false">
                    <a href="javascript:void(0)" class="trait-shop-card__buy trait-shop-card__buy-card"
                        @click.prevent="buyWithCard">
                        <ICard width="18" />Buy with credit card
                    </a>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script setup>
import IBuy from "@/assets/icons/buy.svg?inline";
import IHeart from "@/assets/icons/heart.svg?inline";
import ILock from "@/assets/icons/lock.svg?inline";
import ICard from "@/assets/icons/credit-card.svg?inline";
import IFlag from "@/assets/icons/flag.svg?inline";
import ISale from "@/assets/icons/sale.svg?inline";
import { ModalName } from "@/components/Modal/modal-name";
import { useModal } from "@/composables/useModal";
import { useToast } from "@/composables/useToast";
import { TokensContracts } from "@/config/tokens-contracts";
import { traitShopBuyTraits } from "@/functions/trait-shop/trait-shop-buy-traits";
import { useTraitShopCartStore } from "@/store/trait-shop/trait-shop-cart";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, nextTick, ref } from "vue";
import TraitShopCardCount from "./TraitShopCardCount.vue";
import TraitShopCardTimer from "./TraitShopCardTimer.vue";
import TraitShopCardBundle from "./TraitShopCardBundle.vue";
import { formatNumberSpaces } from "@/helpers/format-number-spaces";
import TraitShopCardButton from "./TraitShopCardButton.vue"

import { useTimer } from "vue-timer-hook";
const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    }
})
const emit = defineEmits(['refresh'])
const cartStore = useTraitShopCartStore()
const walletCollectionStore = useWalletCollectionStore()
const image = computed(() => props.item.rarityUnisexPreviewImageSrc || props.item.rarityFemalePreviewImageSrc || props.item.rarityMalePreviewImageSrc)
const name = computed(() => props.item.name)
const category = computed(() => props.item.categoryName)
const priceMeth = computed(() => props.item.price)
const priceFlags = computed(() => props.item.flagPrice)

const mintedCount = computed(() => props.item.total_minted)
const maxQuantity = computed(() => props.item.maxQuantity)
const maxPerWallet = computed(() => props.item.maxPerWallet)
const leftCount = computed(() => maxQuantity.value < 0 ? Infinity : maxQuantity.value - mintedCount.value)

const maxCount = computed(() => maxPerWallet.value ? Math.min(leftCount.value, maxPerWallet.value) : leftCount.value)

const isInitingCollection = computed(() => walletCollectionStore.isLoading)

const isInCart = computed(() => cartStore.isInCart({ id: 1 }))
const isInWishlist = computed(() => false)

const isSoldOut = computed(() => leftCount.value <= 0 || props.item.isExpired)
const comingSoonTimer = useTimer(props.item.launchDate ? new Date(props.item.launchDate).getTime() : 0, true)
const isComingSoon = computed(() => props.item.comingSoon && !comingSoonTimer.isExpired.value)

const expireTime = useTimer(props.item.expiryDate ? new Date(props.item.expiryDate).getTime() : 0, true)
const isExpiring = computed(() => props.item.expiryDate && !expireTime.isExpired.value)

const isBundle = computed(() => props.item.type === 'sets')

const isTimer = computed(() => props.item.expiryDate)

const isBuyCard = computed(() => props.item.credit_card)
const isSale = computed(() => props.item.isOnSale)
const oldPrice = computed(() => props.item.old_price)
const activeAction = ref('')
const showCount = computed(() => ['buy', 'cart'].includes(activeAction.value))
const isLoading = ref(false)
const communityName = computed(() => props.item.brandName)
const isTokenLimited = computed(() => !!props.item.brandAddress)
const hasCommunityToken = computed(() => {
    const communityAddress = props.item.brandAddress
    const partners = walletCollectionStore.partners
    const hasInPartners = Object.values(partners).findIndex(partnerCollection =>
        partnerCollection.findIndex(token => token.token_address.toLowerCase() === communityAddress.toLowerCase())
        > -1)
        > -1
    if (hasInPartners) {
        return true
    }
    // search for other tokens
    const tokenType = Object.keys(TokensContracts).find(type => TokensContracts[type].contractAddress.toLowerCase() === communityAddress.toLowerCase())
    if (!tokenType) return false
    return walletCollectionStore.allTokens[tokenType].length > 0
})
const isLocked = computed(() => isTokenLimited.value && !hasCommunityToken.value)
const runAction = (name) => {
    activeAction.value = name
}
const addToCart = (count) => {
    try {
        if (maxCount.value <= cartStore.items.find((itm) => itm.id === props.item.id)?.cnt) {
            useToast().error({ title: 'You cannot add more of these traits to your cart' })
            return
        }
        cartStore.addToCart({ id: props.item.id, cnt: count })
        nextTick(() => cartStore.showCart())
    } catch (err) {
        console.log(err)
    }
    useToast().success({ title: "Success!", text: `${props.item.name} x${count} successfully added to cart ` })
    cancelAction()
}
const buyTrait = async (count) => {
    isLoading.value = true
    const traits = [
        {
            id: props.item.id,
            count,
            name: props.item.name
        }
    ]
    const isFlagPayment = !priceMeth.value && priceFlags.value > 0
    const isSuccess = await traitShopBuyTraits(traits, isFlagPayment)
    if (isSuccess) {
        cancelAction()
        emit('refresh')
    }
    isLoading.value = false
}

const buyWithCard = () => {
    runAction('card')
}

const addToWishlist = () => {

}
const onLockClick = () => {
    useToast().error({
        title: "You cannot buy this trait",
        text: `You need to own a Trait from ${communityName.value} in the wallet to be able to buy this trait. Please click on the <a href="#">link</a> to ask for a trait designed for your community`
    })
}
const acceptAction = (count) => {
    if (activeAction.value === 'cart') {
        addToCart(count)
    }

    if (activeAction.value === 'buy') {
        buyTrait(count)
    }
}
const cancelAction = () => {
    activeAction.value = null
}

const zoomImage = () => {
    useModal().open({ name: ModalName.ImageModal, props: { image: image.value } })
}
</script>
<style lang="scss">
.trait-shop-card {
    background-color: #0a0a0a;
    border: 1px solid #121212;
    border-radius: 16px;
    padding: 12px;
    padding-top: 16px;
    min-width: 226px;
    box-sizing: border-box;
    font-family: var(--font-oxanium);
    position: relative;
    transition: 0.2s;

    &:hover {
        box-shadow: 0 0 20px 0px rgba(189, 189, 189, 25%);
    }

    &__image {
        margin-bottom: 14px;

        img {
            width: 100%;
            height: auto;
            max-width: 100%;
            cursor: pointer;
        }
    }

    &__sold-out {
        text-transform: uppercase;
        font-size: 18px;
    }

    &__coming-soon {
        text-transform: uppercase;

        &-timer {
            text-transform: none;
            margin-top: 5px;
            opacity: 0.7;
        }
    }

    &__locked,
    &__sold-out,
    &__coming-soon {
        position: absolute;
        background-color: rgba(0, 0, 0, 0.9);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        text-align: center;
    }

    &__locked {
        span {
            color: var(--primary);
        }

        svg {
            margin-bottom: 5px;
        }

        &-loading {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 10;
        }
    }

    &__sale {
        position: absolute;
        top: -12px;
        right: -12px;
        color: var(--yellow);

    }

    &__category {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #232323;
        padding: 3px 8px;
        font-size: 14px;
        display: inline-block;
        box-sizing: border-box;
        border-radius: 24px;
    }

    &__name {
        margin-bottom: 10px;
        font-size: 18px;
        text-align: center;
    }

    &__price {
        display: flex;
        align-items: center;

        &-title {
            font-weight: 500;

            margin-right: auto;
            opacity: 0.5;
            text-transform: uppercase;
            font-size: 14px;
        }

        &-old {
            text-decoration: line-through;
            font-size: 14px;
            opacity: 0.5;
            text-align: right;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            margin-top: -18px;

            img {
                margin-right: 5px;
            }
        }

        &.has-old {
            margin-top: 20px;
        }

        &-value {
            &-del {
                display: flex;
                align-items: center;
                margin: 4px 0;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.5);

                &::before,
                &::after {
                    content: "";
                    height: 1px;
                    display: flex;
                    flex: 1;
                    background-color: var(--border);
                }

                span {
                    margin: 0 5px;
                }
            }

            &-row {
                display: flex;
                align-items: center;
                width: 100%;
            }
        }

        &-icon {
            margin-right: 4px;
            color: var(--yellow);
            flex-shrink: 0;
        }
    }

    &__wishlist {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid var(--border);
        color: #fff;
        box-sizing: border-box;
        margin-right: 5px;
        transition: 0.2s;

        &:hover {
            background-color: var(--gray-light);
        }
    }

    &__minted-count {
        margin-bottom: 5px;
        margin-top: 5px;
        display: flex;
        align-items: baseline;

        span {
            font-size: 14px;
            font-weight: 500;
            opacity: 0.5;
            text-transform: uppercase;
            margin-right: auto;
        }
    }

    &__add-to-cart {
        padding: 8px 10px;
        text-align: center;
        box-sizing: border-box;
        border-radius: 25px;
        background-color: var(--purple);
        text-decoration: none;
        color: #fff;
        display: inline-block;
        width: 100%;
        transition: 0.2s;

        &:hover {
            filter: brightness(0.8);
        }
    }

    &__buy {
        padding: 6px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border);
        box-sizing: border-box;
        color: #fff;
        border-radius: 25px;
        text-decoration: none;

        svg {
            margin-right: 5px;
        }

        transition: 0.2s;

        &:hover {
            background-color: var(--gray-light);
        }
    }

    &__expiring {
        padding: 6px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--border);
        box-sizing: border-box;
        color: #fff;
        border-radius: 25px;
        text-decoration: none;

        svg {
            margin-right: 5px;
        }

        transition: 0.2s;

        &:hover {
            // do nothing
            cursor: default;
        }
    }

    &__actions {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        height: 80px;
        position: relative;

        &-row {
            display: flex;
            align-items: center;
            margin-top: 10px;
        }
    }

    &.sale {
        box-shadow: 0 0 10px 3px var(--yellow);
        animation: pulse 1.5s infinite;

        @keyframes pulse {

            0% {
                box-shadow: 0 0 10px 1px var(--yellow);

            }

            50% {
                box-shadow: 0 0 15px 5px var(--yellow);

            }
            100% {
                box-shadow: 0 0 10px 1px var(--yellow);

            }
        }
    }
}

.t-trait-shop-slide {
    &-enter-from {
        opacity: 0;
        transform: translateX(-50%);
    }

    &-leave-to {
        opacity: 0;
        transform: translateX(50%);
    }

    &-enter-active,
    &-leave-active {
        transition: 0.2s;
    }
}
</style>