<template>
    <div class="trait-shop-tabs">
        <a
            v-for="(tab, idx) in tabs"
            :key="idx"
            href="javascript:void(0)"
            class="trait-shop-tab"
            :class="{ active: activeTab === tab.value }"
            @click.prevent="selectTab(tab.value)"
        >
            {{ tab.name }}
            <img v-if="tab.icon" :src="tab.icon" width="14">
        </a>
    </div>
</template>
<script setup>
defineProps({ activeTab: String, tabs: Array })
const emit = defineEmits(['update:activeTab'])
const selectTab = (tab) => {
    emit('update:activeTab', tab)
}

</script>
<style lang="scss">
.trait-shop-tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @media screen and (max-width: 450px) {
        flex-direction: column;
    }
}
.trait-shop-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    transition: 0.2s;
    text-decoration: none;
    color: #fff;
    border-radius: 25px;
    margin-right: 10px;
    &:last-child {
        margin-right: 0;
    }
    img {
        margin-left: 5px;
    }
    &:hover {
        background-color: var(--gray-light);
    }
    &.active {
        background-color: var(--purple);
    }
    @media screen and (max-width: 450px) {
        margin-right: 0;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>