<template>
    <div class="trait-shop-card-bundle">
        <div class="trait-shop-card-bundle-title">
            <IInfo width="20" />
            <div>
                This Set includes
                <span>{{ items.length }}</span> individual traits that will be sent to your wallet upon purchase
            </div>
        </div>

        <div class="trait-shop-card-bundle-info" @click.prevent="toggle">
            See what's inside
            <IArrowRight width="7" :class="{ open: isOpen }" />
        </div>
        <div class="trait-shop-card-bundle-items" v-if="isOpen" v-click-outside="close">
            <div class="trait-shop-card-bundle-item" v-for="(item, idx) in items" :key="idx">
                <ApeTrait :show-category="true" :show-rank="false" :item="item" />
            </div>
        </div>
    </div>
</template>
<script setup>
import IInfo from "@/assets/img/info.svg?inline"
import { ref } from "vue-demi";
import ApeTrait from "@/components/ApeTrait/ApeTrait.vue";
import IArrowRight from "@/assets/icons/ape-constructor/ape-constructor-arrow-right.svg?inline"
const props = defineProps({ items: Array })
const isOpen = ref(false)
const toggle = () => {
    isOpen.value = !isOpen.value
}
const close = () => {
    isOpen.value = false
}
</script>
<style lang="scss">
.trait-shop-card-bundle {
    &-title {
        display: flex;
        align-items: center;
        font-size: 14px;
        span {
            color: var(--primary);
        }
        svg {
            margin-right: 5px;
            flex-shrink: 0;
        }
    }
    &-info {
        color: var(--primary);
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        margin-top: 5px;

        border-bottom: 1px solid transparent;
        transition: border 0.2s;

        &:hover {
            border-bottom: 1px solid var(--primary);
        }
        svg {
            margin-left: 5px;
            transition: 0.2s;
            &.open {
                transform: rotate(90deg);
            }
        }
    }
    &-items {
        position: absolute;
        width: 350px;
        left: 50%;
        z-index: 10;
        transform: translate(-50%, -100%);
        margin-top: -30px;
        height: 300px;
        background-color: var(--gray-light);
        padding: 5px;
        border-radius: 5px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid var(--border);
        padding: 8px 0;
    }
    &-item {
        width: calc(50% - 10px);
        margin: 12px 5px;
        background: var(--bg);
        .ape-trait__category {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 380px) {
        &-items {
            width: 100%;
        }
    }
}
</style>