<template>
    <div class="trait-shop-card-count-wrapper">
        <div class="trait-shop-card-count__title">
Select Count
</div>
        <div class="trait-shop-card-count">
            <ACounter v-model:value="countModel" />
            <div class="trait-shop-card-count__actions">
                <a
                    href="#"
                    class="trait-shop-card-count__action trait-shop-card-count__cancel"
                    @click.prevent="cancel"
                >Cancel</a>
                <a
                    href="#"
                    class="trait-shop-card-count__action trait-shop-card-count__accept"
                    :class="{ disabled }"
                    @click.prevent="accept"
                >{{ acceptText || 'Accept' }}</a>
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, defineComponent, ref } from "vue"
import ACounter from "@/components/ACounter.vue"
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
import ICross from "@/assets/icons/cross.svg?inline"
const props = defineProps({ maxCount: Number, acceptText: String, disabled: Boolean })
const emit = defineEmits(['accept', 'cancel'])
const count = ref(1)
const countModel = computed({
    get() {
        return count.value
    },
    set(val) {
        if (val > props.maxCount) return
        count.value = val
    }
})
const accept = () => {
    emit('accept', count.value)
}
const cancel = () => {
    emit('cancel')
}
</script>
<style lang="scss">
.trait-shop-card-count {
    align-items: center;
    justify-content: center;
    &-wrapper {
        height: 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;
    }
    &__title {
        text-align: center;
        margin-bottom: 5px;
        text-transform: uppercase;
        font-size: 14px;
        opacity: 0.5;
    }
    &__actions {
        display: flex;
        justify-content: center;
        margin-top: 6px;
    }
    &__accept {
        background-color: var(--green);
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }
    &__cancel {
        margin-right: 8px;

        background-color: var(--red);
    }
    &__action {
        border-radius: 15px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        flex: 1;
        font-size: 14px;
        text-decoration: none;
        transition: 0.2s;
        svg {
            margin-right: 5px;
        }
        &:hover {
            filter: brightness(0.8);
        }
    }
}
</style>