<template>
    <div class="trait-shop-balance">
        <div v-if="canBeController" class="trait-shop-balance-wallet">
            <div class="trait-shop-balance-wallet-address">
                <span>Wallet Address</span>
                {{ walletAddress }}
            </div>
            <CollectionSafeClaimSwitcher />
        </div>
        <div class="trait-shop-balance-items">
            <div class="trait-shop-balance-item">
                <div class="trait-shop-balance-item__title">Balance</div>
                <div class="trait-shop-balance-item__value">{{ walletBalance }} $ETH</div>
            </div>
            <div class="trait-shop-balance-item">
                <div class="trait-shop-balance-item__title">On-Chain</div>
                <div class="trait-shop-balance-item__value"><ANumberAnimate comp="span" :num="walletMethBalance" />&nbsp; Methane</div>
            </div>
            <div class="trait-shop-balance-item">
                <div class="trait-shop-balance-item__title">Meth Bank</div>
                <div class="trait-shop-balance-item__value">
                    <ANumberAnimate comp="span" :num="methBankBalance" />&nbsp;Methane
                </div>
            </div>
            <div class="trait-shop-balance-item">
                <div class="trait-shop-balance-item__title">Achievement Flags</div>
                <div class="trait-shop-balance-item__value">
                    <ANumberAnimate comp="span" :num="achievementFlagsBalance" />&nbsp;Flags
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>import { useMethBankStore } from "@/store/meth-bank";
import { useWeb3Store } from "@/store/web3";
import { computed } from "vue-demi";
import ANumberAnimate from "@/components/ANumberAnimate/ANumberAnimate.vue";
import { shorten } from "@/helpers/utils";
import CollectionSafeClaimSwitcher from "@/components/Collection/CollectionTopActions/CollectionSafeClaimSwitcher.vue";
import { useWalletCollectionStore } from "@/store/wallet-collection";

const methBankBalance = computed(() => useMethBankStore().balance)
const walletBalance = computed(() => useWeb3Store().balance.toFixed(4))
const walletMethBalance = computed(() => useWeb3Store().meth)
const achievementFlagsBalance = computed(() => useWalletCollectionStore().achievementFlagsBalance.all)
const web3Store = useWeb3Store()
const walletAddress = computed(() => shorten(web3Store.walletAddress, 4, 4))

const canBeController = computed(() => web3Store.canBeController)

</script>
<style lang="scss">
.trait-shop-balance {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--ape-constructor-gray);
    &-items {
        display: flex;
        align-items: center;
    }
    &-wallet {
        border-bottom: 1px solid var(--ape-constructor-gray);
        margin-bottom: 10px;
        &-address {
            font-size: 14px;
            margin-bottom: 3px;
            span {
                opacity: 0.5;
                font-size: 12px;
                text-transform: uppercase;
                margin-right: 5px;
            }
        }
        .collection-page-safe-claim-switch-button {
            padding: 8px 15px;
            font-size: 14px;
        }
        @media screen and (max-width: 380px) {
            &-address {
                font-size: 12px;
            }
            .collection-page-safe-claim-switch-button {
                font-size: 12px;
                padding: 6px 10px;
            }
        }
    }
    &-item {
        margin-right: 10px;
        flex: 1;
        text-align: center;

        &:last-child {
            margin-right: 0;
        }
        &__title {
            text-transform: uppercase;
            opacity: 0.5;
            font-size: 14px;
            margin-bottom: 4px;
            font-weight: 500;
        }
        &__value {
        }
    }
    @media screen and (max-width: 768px) {
        &-items {
            flex-direction: column;
        }
        &-item {
            margin-bottom: 10px;
            margin-right: 0;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
</style>