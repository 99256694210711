<template>
  <div class="trait-shop-header">
    <div>
      <h2 class="trait-shop-header-title">Welcome to the shop</h2>
      <div class="trait-shop-header-description">buy traits for your apes!</div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue-demi";
export default defineComponent({
  components: {},
  setup() {

    return {}
  }
})
</script>
<style lang="scss">
.trait-shop-header {
  padding-bottom: 20px;
  margin: 0 auto;
  border-bottom: 1px solid var(--ape-constructor-gray);
  margin-bottom: 10px;

  &-title {
    font-size: 32px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-bottom: 12px;
  }
  &-description {
    font-size: 18px;
    text-transform: uppercase;
    color: #7e7e7e;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 5px;
    padding-bottom: 10px;
    &-back {
      font-size: 14px;
      padding: 4px 15px;
    }
    &-title {
      font-size: 18px;
      margin-bottom: 5px;
    }
    &-description {
      font-size: 14px;
    }
  }
}
</style>