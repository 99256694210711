<template>
    <div class="trait-shop">
        <div v-if="isConnected" class="trait-shop-container">
            <TraitShopTopActions @openCart="openPanel('cart')" />
            <TraitShopHeader />
            <TraitShopBalance />
            <TraitShopSearchBar v-model:search="searchStr" v-model:sort="sort" />
            <TraitShopTabs :tabs="tabs" v-model:activeTab="tab" />
            <TraitShopCategories v-model:activeCategories="activeCategories" :items="categories" />

            <div class="trait-shop-filter-soldout">
                <span>Available items</span>
                <ASwitcher v-model:value="isAvailable" />
            </div>
            <div v-if="isLoading" class="trait-shop__loading">
                <img class="loader" src="@/assets/img/spinner-clear.svg" width="80" />
            </div>
            <template v-else>
                <TraitShopItems
                    v-if="traits.length"
                    :items="traits"
                    @loadMore="loadMoreItems"
                    @refresh="fetchItems(true)"
                />
                <div v-else class="trait-shop__empty">
                    <div
                        v-if="hasActiveFilters"
                    >There are no traits matching your filter, try to change the filter criteria</div>
                    <div v-else>There are no traits</div>
                </div>
            </template>
        </div>
        <div v-else class="trait-shop-container">
            <TraitShopTopActions @openCart="openPanel('cart')" />
            <TraitShopHeader />
            <TraitShopConnect />
        </div>
        <Transition name="t-trait-shop-panel">
            <TraitShopCart
                v-if="cartStore.isOpen"
                @refresh="fetchItems(true)"
                @close="closePanel('cart')"
            />
        </Transition>
    </div>
</template>
<script setup>
import { useApesStorageApi } from "@/api/useApesStorageApi";
import TraitShopHeader from "@/components/TraitShop/TraitShopHeader.vue";
import TraitShopItems from "@/components/TraitShop/TraitShopItems/TraitShopItems.vue";
import { usePageWithWallet } from "@/composables/usePageWithWallet";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { computed, ref, watch } from "vue-demi";
import TraitShopTabs from "@/components/TraitShop/TraitShopTabs/TraitShopTabs.vue";
import TraitShopSearchBar from "@/components/TraitShop/TraitShopSearchBar/TraitShopSearchBar.vue";
import TraitShopTopActions from "@/components/TraitShop/TraitShopTopActions/TraitShopTopActions.vue";
import { captureError } from "@/helpers/captureError";
import TraitShopBalance from "@/components/TraitShop/TraitShopBalance/TraitShopBalance.vue";
import TraitShopCart from "@/components/TraitShop/TraitShopCart/TraitShopCart.vue";
import { useTraitShopCartStore } from "@/store/trait-shop/trait-shop-cart";
import TraitShopConnect from "@/components/TraitShop/TraitShopConnect/TraitShopConnect.vue";
import { useApiGetApeCategories } from "@/api/apes/get-categories";
import TraitShopCategories from "@/components/TraitShop/TraitShopCategories/TraitShopCategories.vue";
import { useRoute } from "vue-router";
import ASwitcher from "@/components/ASwitcher.vue";
const page = ref(1)
const route = useRoute()


const tab = ref('all')
const searchStr = ref('')
const sort = ref('')
const perPage = 10
const traits = ref([])
const canLoadMore = ref(true)
const isLoading = ref(true)

const isAvailable = ref(true)

const activePanel = ref('')
const cartStore = useTraitShopCartStore()
const categories = ref([])
const activeCategories = ref([])
const hasActiveFilters = computed(() => activeCategories.value.length > 0)

const tabs = [
    {
        name: "All items",
        value: 'all'
    },
    {
        name: "New traits",
        value: "newly_created"
    },
    {
        name: "BOTB",
        value: "botb",
    },
    // {
    //     name: "Events",
    //     value: "events",
    //     icon: require('@/assets/img/star-badge.png')
    // },

]
const loadMoreItems = () => {
    page.value++
    fetchItems()
}

const initTab = () => {
    const routeTab = route?.hash?.slice(1)
    if (tabs.findIndex(tab => tab.value === routeTab) > -1) {
        tab.value = routeTab
    }
}
initTab()
const fetchCategories = async () => {
    const [traitsCount] = await Promise.all([
        // useApiGetApeCategories({ toast: { error: true }, throw: true }).exec(),
        useApesStorageApi({ toast: { error: true } }).exec({
            url: "/traits-shop/categories-map",
            params: {
                tab: tab.value !== 'all' ? tab.value : '',

            }
        })])
    // categories.value = items.filter(item => Object.keys(traitsCount).includes(item.name)).map(item => {
    //     const count = traitsCount[item.name] || 0
    //     return { ...item, traitsCount: count }
    // })
    categories.value = Object.keys(traitsCount).map(itemName => {
        const count = traitsCount[itemName] || 0
        return { name: itemName, traitsCount: count }
    })
}
fetchCategories()
const fetchItems = async (isReset) => {
    try {
        if (isReset) {
            isLoading.value = true
            canLoadMore.value = true
            page.value = 1
        }
        if (!canLoadMore.value) return
        const sortName = sort.value.split('-')[0]
        const sortOrder = sort.value.split('-')[1]
        const data = await useApesStorageApi({ toast: { error: true } })
            .exec({
                url: "/traits-shop/list",
                params: {
                    name: searchStr.value,
                    page: page.value,
                    tab: tab.value !== 'all' ? tab.value : '',
                    name: searchStr.value,
                    sort: sortName,
                    order: sortOrder,
                    isAvailable: isAvailable.value ? isAvailable.value : undefined,
                    category: activeCategories.value.length ? activeCategories.value : undefined,
                    comingSoon: true
                }
            })
        if (data.currentPage >= data.totalPages) {
            canLoadMore.value = false
        }
        if (isReset) {
            traits.value = []
        }
        traits.value = [...traits.value, ...data.traits]
    } catch (err) {
        captureError(err)
    }
    isLoading.value = false

}
watch(sort, () => {
    fetchItems(true)
})
watch(isAvailable, () => {
    fetchItems(true)
})

watch(searchStr, () => {
    fetchItems(true)
})
watch(tab, () => {
    fetchItems(true)
    fetchCategories()
})
watch(() => route.hash, initTab)
watch(activeCategories, () => {
    fetchItems(true)
})
const showPanel = panelName => activePanel.value === panelName
const openPanel = panelName => {
    if (panelName === 'cart') {
        cartStore.showCart()
    }
}
const closePanel = panelName => {
    if (panelName === 'cart') {
        cartStore.hideCart()
    }
}
fetchItems(true)

const { isConnected, connect } = usePageWithWallet({
    async onInit(connected) {
        if (connected) {
            useMethBankStore().fetchMethBank()
            useWalletCollectionStore().fetchCollection()
            useWalletCollectionStore().fetchApeTraits()
        }
    },
    onWalletChange: (isConnected) => {
        if (isConnected) {
            useMethBankStore().fetchMethBank()
            useWalletCollectionStore().partners = {}
            useWalletCollectionStore().fetchCollection()
            useWalletCollectionStore().fetchApeTraits()

        }

    }
})
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&family=Oxanium:wght@500;700;800&display=swap");

.trait-shop {
    font-family: var(--font-oxanium);
    font-weight: bold;
    padding: 0 40px;
    padding-top: 20px;
    box-sizing: border-box;
    background-color: #000;
    flex: 1;
    overflow: hidden;
    &-container {
        max-width: 1400px;
        margin: 0 auto;
    }
    &__empty {
        text-align: center;
        font-size: 18px;
        padding: 0 0 30px;
    }
    &__loading {
        display: flex;
        justify-content: center;
        padding: 40px 0;
    }
    &-filter-soldout {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--border);
        box-sizing: border-box;
        > span {
            margin-right: 8px;
            opacity: 0.5;
        }
    }
    @media screen and (max-width: 576px) {
        padding: 0 15px;
        padding-top: 20px;
    }
}
.t-trait-shop-panel-enter-from .trait-shop-panel,
.t-trait-shop-panel-leave-to .trait-shop-panel {
    transform: translateX(100%);
}
.t-trait-shop-panel-enter-from .trait-shop-panel-overlay,
.t-trait-shop-panel-leave-to .trait-shop-panel-overlay {
    opacity: 0;
}
.t-trait-shop-panel-enter-active,
.t-trait-shop-panel-leave-active {
    transition: 0.2s;
}
</style>