import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useToast } from "@/composables/useToast";
import { captureError } from "@/helpers/captureError";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { web3 } from "@/web3";

const messageToSign = "I’m buying from The Shop";

/**
 *
 * @param {Array} traits | [{ id, count }]
 */
export const traitShopBuyTraits = async (traits, isFlagPayment = false) => {
  try {
    const wallet = useWeb3Store().collectionWallet;
    let signature;
    try {
      signature = await web3.eth.personal.sign(
        messageToSign,
        useWeb3Store().walletAddress
      );
    } catch (err) {
      useToast().error({ title: "Couldn't sign the message" });
      console.error(err);
    }

    if (!signature) return false;
    const traitsArr = traits.reduce((arr, trait) => {
      for (let i = 0; i < trait.count; i++) {
        arr.push(trait.id);
      }
      return arr;
    }, []);
    await useApesStorageApi({ throw: true, toast: { error: true } }).exec({
      url: "/traits-shop/buy-off-chain",
      method: "POST",
      data: {
        isFlagPayment,
        traits: traitsArr,
        wallet: wallet,
        signature,
      },
    });
    useToast().success({
      title: "Congrats!",
      text: `You successfuly bought ${traits.reduce(
        (str, trait, index) =>
          (str += `${trait.name} ${trait.count > 1 ? "x" + trait.count : ""}${
            index !== traits.length - 1 ? ", " : ""
          }`),
        ""
      )}`,
    });
    useMethBankStore().fetchMethBank();
    if (isFlagPayment) {
      useWalletCollectionStore().fetchApeTraits()
    }
    return true;
  } catch (err) {
    captureError(err);
  }
};
