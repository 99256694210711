<template>
  <div class="trait-shop-items">
    <div v-for="(item, idx) in items" :key="idx" class="trait-shop-items__item">
      <TraitShopCard :item="item" @refresh="$emit('refresh')" />
    </div>
    <InfiniteLoading @infinite="loadMore" />
  </div>
</template>
<script setup>
import TraitShopCard from "../TraitShopCard/TraitShopCard.vue";
import InfiniteLoading from "v3-infinite-loading";
const emit = defineEmits(['loadMore', 'refresh'])
const props = defineProps({ items: { type: Array, default: () => ([]) } })
const loadMore = () => {
  emit('loadMore')
}

</script>
<style lang="scss">
.trait-shop-items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -10px;
  &__item {
    width: calc(100% / 5 - 20px);
    padding: 10px;
  }
  @media screen and (max-width: 1350px) {
    &__item {
      width: calc(100% / 4 - 20px);
    }
  }
  @media screen and (max-width: 1100px) {
    &__item {
      width: calc(100% / 3 - 20px);
    }
  }
  @media screen and (max-width: 800px) {
    &__item {
      width: calc(100% / 2 - 20px);
    }
  }
  @media screen and (max-width: 520px) {
    margin: 0;

    &__item {
      width: 100%;
      padding: 20px 0;
    }
  }
}
</style>