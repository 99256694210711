import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { useWeb3Store } from "../web3";

export const useTraitShopCartStore = defineStore("trait-shop-cart", {
  state: () => ({
    // {id, cnt}
    populatedItems: [],
    isOpen: false
  }),
  getters: {
    CART_KEY() {
      return "bap-shop-cart-" + useWeb3Store().collectionWallet;
    },
    itemsCount() {
      return this.items.length;
    },
    isInCart() {
      return (item) => {
        return this.items.findIndex((t) => t.id == item.id) > -1;
      };
    },
    items() {
      const storageVal = useStorage(this.CART_KEY, [], localStorage).value;
      if (!Array.isArray(storageVal)) return [];
      return storageVal;
    },
    totalPrice() {
      return this.items.reduce((sum, item) => {
        const { cnt, id } = item;
        const populatedItem = this.populatedItems.find((item) => item.id == id);
        if (!populatedItem) return sum;
        const itemPrice = populatedItem.price * cnt;
        return sum + itemPrice;
      }, 0);
    },
  },
  actions: {
    showCart() {
      this.isOpen = true
    },
    hideCart(){
      this.isOpen = false
    },
    addToCart(item) {
      const itemIdx = this.items.findIndex((itm) => itm.id === item.id);
      const newItems = [...this.items];
      if (itemIdx > -1) {
        newItems[itemIdx] = {
          ...this.items[itemIdx],
          cnt: this.items[itemIdx].cnt + item.cnt,
        };
      } else {
        newItems.push({
          id: item.id,
          cnt: item.cnt,
        });
      }
      useStorage(this.CART_KEY, [], localStorage).value = newItems;
    },
    clearCart() {
      useStorage(this.CART_KEY, [], localStorage).value = [];
    },
    // {id, cnt}
    updateItemCnt(item) {
      const itemIdx = this.items.findIndex((itm) => itm.id === item.id);
      const newItems = [...this.items];
      if (itemIdx > -1) {
        newItems[itemIdx] = {
          ...this.items[itemIdx],
          cnt: item.cnt,
        };
      }
      useStorage(this.CART_KEY, [], localStorage).value = newItems;
    },
    removeItem(id) {
      const newItems = this.items.filter((item) => item.id !== id);
      useStorage(this.CART_KEY, [], localStorage).value = newItems;
    },
    async populateCart() {
      if (!this.items.length) return;
      const items = await useApesStorageApi().exec({
        url: "/traits-shop/batch",
        params: {
          ids: this.items.map((item) => item.id).join(","),
        },
      });
      this.populatedItems = items;
    },
  },
});
