<template>
    <div class="trait-shop-sort">
        <ASelect
            :class="{ active: !!value }"
            :items="sortOptions"
            :value="value"
            @update:value="$emit('update:value', $event)"
        >
            <template #icon>
                <IFilter width="14" />
            </template>
            <template #name="activeItem">
{{ activeItem.value ? activeItem.name : "Sort By" }}
</template>
        </ASelect>
    </div>
</template>
<script setup>
import IFilter from "@/assets/icons/filter.svg?inline"
import ASelect from "@/components/ASelect.vue";
defineProps({ value: String })
const sortOptions = [
    {
        name: "Default",
        value: ""
    },
    {
        name: "Rank: High to Low",
        value: "rank-desc"
    },
    {
        name: "Rank: Low to High",
        value: "rank-asc"
    },
    {
        name: "Total Minted: High to Low",
        value: "total_minted-desc"
    },
    {
        name: "Total Minted: Low to High",
        value: "total_minted-asc"
    },


]
</script>
<style lang="scss">
.trait-shop-sort {
    .app-select {
        min-width: 220px;
        width: auto;
        &-header {
            white-space: nowrap;
            border: none;
            background-color: #0d0d0d;
            color: rgba(255, 255, 255, 0.5);
            span {
            }
        }
        &.active .app-select-header {
            color: #fff;
        }
    }
}
</style>