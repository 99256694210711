<template>
    <div class="trait-shop-categories">
        <a
            v-for="(category, idx) in items"
            :key="idx"
            href="javascript:void(0)"
            class="trait-shop-categories__item"
            :class="{ active: activeCategories.includes(category.name) }"
            @click.prevent="selectCategory(category)"
        >{{ category.name }} <span v-if="category.traitsCount">({{ category.traitsCount }})</span></a>
    </div>
</template>
<script setup>
const props = defineProps({
    items: {
        type: Array,
        default: () => []
    },
    activeCategories: {
        type: Array,
        default: () => []
    }
})
const emit = defineEmits(['update:activeCategories'])

const selectCategory = (category) => {
    const categoryName = category.name
    if (props.activeCategories.includes(categoryName)) {
        emit('update:activeCategories', props.activeCategories.filter(cat => cat !== categoryName))
    } else {
        emit('update:activeCategories', [...props.activeCategories, categoryName])
    }
}
</script>
<style lang="scss">
.trait-shop-categories {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -4px;
    padding: 10px 0;
    border-bottom: 1px solid var(--ape-constructor-gray);
    border-top: 1px solid var(--ape-constructor-gray);
    margin-bottom: 15px;

    &__item {
        padding: 5px 12px;
        border-radius: 24px;
        border: 1px solid var(--border);
        box-sizing: border-box;
        color: #fff;
        text-decoration: none;
        margin: 4px;
        white-space: nowrap;
        transition: .2s;
        &:hover {
            background-color: var(--gray-light);
        }
        &.active {
            background-color: var(--border);
        }
    }
}
</style>