<template>
    <TraitShopPanel class="trait-shop-cart__panel" title="Cart" @close="$emit('close')">
        <template #default>
            <div class="trait-shop-cart__body">
                <div v-if="!isLoading" class="trait-shop-cart__items">
                    <TraitShopCartItem v-for="(item, idx) in items" :key="idx" :item="item" />
                </div>
                <div v-else class="trait-shop-cart__loading">
                    <img src="@/assets/img/spinner-clear.svg" class="loader" width="50">
                </div>
            </div>
        </template>
        <template #actions>
            <div class="trait-shop-cart__actions">
                <div v-if="!isLoading" class="trait-shop-cart-summary">
                    <div class="trait-shop-cart-summary__item">
                        <div class="trait-shop-cart-summary__item-title">
Total Items
</div>
                        <div class="trait-shop-cart-summary__item-value">
{{ totalCount }}
</div>
                    </div>
                    <div class="trait-shop-cart-summary__item">
                        <div class="trait-shop-cart-summary__item-title">
Total Price
</div>
                        <div class="trait-shop-cart-summary__item-value">
{{ totalPrice }} METH
</div>
                    </div>
                </div>
                <button
                    class="trait-shop-cart__button"
                    :class="{ disabled: isBuying || isLoading || totalCount < 1 }"
                    @click.prevent="buyCart"
                >
Buy for {{ totalPrice }} METH
</button>
            </div>
        </template>
    </TraitShopPanel>
</template>
<script setup>
import { traitShopBuyTraits } from "@/functions/trait-shop/trait-shop-buy-traits";
import { formatNumberSpaces } from "@/helpers/format-number-spaces";
import { useTraitShopCartStore } from "@/store/trait-shop/trait-shop-cart";
import { computed, ref } from "vue-demi";
import TraitShopPanel from "../TraitShopPanel/TraitShopPanel.vue"
import TraitShopCartItem from "./TraitShopCartItem.vue";
defineProps({
    isOpen: Boolean
})
const emit = defineEmits(['close', 'refresh'])
const cartStore = useTraitShopCartStore()

const items = computed(() => {
    return cartStore.items.map(item => cartStore.populatedItems.find(itm => itm.id === item.id)).filter(Boolean)
})
const isLoading = ref(false)
const fetchCart = async () => {
    isLoading.value = true
    await cartStore.populateCart()
    isLoading.value = false
}
fetchCart()
const isBuying = ref(false)
const buyCart = async () => {
    isBuying.value = true
    const done = await traitShopBuyTraits(cartStore.items.map(item => {
        const currentItem = cartStore.populatedItems.find(itm => itm.id === item.id)
        return { id: item.id, count: item.cnt, name: currentItem?.name }
    }))
    if (done) {
        cartStore.clearCart()
        emit('refresh')
        emit('close')
    }
    isBuying.value = false
}

const totalPrice = computed(() => formatNumberSpaces(cartStore.totalPrice))

const totalCount = computed(() => cartStore.items.reduce((sum, item) => sum + item.cnt, 0))
</script>
<style lang="scss">
.trait-shop-cart {
    &__body {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }
    &__actions {
        border-top: 1px solid var(--border);
        padding: 15px;
        margin-top: auto;
    }
    &-summary {
        margin-bottom: 10px;
        &__item {
            display: flex;
            justify-content: space-between;
            &-title {
                text-transform: uppercase;
                opacity: 0.5;
                margin-right: 10px;
                font-size: 14px;
            }
            &-value {
            }
        }
    }
    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    &__button {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        border: none;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
        border-radius: 10px;
        font-family: inherit;
        background-color: var(--purple);
        cursor: pointer;
        transition: 0.2s;
        &:hover {
            filter: brightness(0.8);
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
</style>