<template>
  <div
    v-if="canBeController"
    class="collection-page-safe-claim-switch-wrapper"
  >
    <a
      href="#"
      class="collection-page-safe-claim-switch-button collection-page-top-actions-button"
      @click.prevent="switchSafeClaim"
    >
      <ILock width="14" />
      <template v-if="isController">
        Click to Switch SafeClaim to
        <span>Normal view</span>
      </template>
      <template v-else>
        Click to Switch SafeClaim to
        <span>Source view</span>
      </template>
    </a>
    <div
      v-if="isController"
      class="collection-page-safe-claim-switch-tooltip"
    >
      Currently viewing
      <b>{{ controllerSource }}</b>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent } from "vue";
import { shorten } from "@/helpers/utils"
import { useWeb3Store } from "@/store/web3";
import ILock from "@/assets/icons/lock.svg?inline"
export default defineComponent({
    components: { ILock },
    setup() {
        const web3Store = useWeb3Store()
        const canBeController = computed(() => web3Store.canBeController)
        const isController = computed(() => web3Store.safeClaim.isController)
        const controllerSource = computed(() => shorten(web3Store.safeClaim.sourceWallet, 4, 4))
        const switchSafeClaim = () => {
            web3Store.switchSafeClaim()
        }
        return { canBeController, isController, controllerSource, shorten, switchSafeClaim }
    }
})
</script>