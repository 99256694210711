<template>
    <div class="ape-trait" :class="{ 'selection-mode': isSelectionMode }">
        <div
            class="ape-trait__category"
            v-if="item.categoryName && showCategory"
        >
{{ item.categoryName }}
</div>

        <div class="ape-trait__image" @click.prevent="$emit('select')">
            <img :src="image" alt="ape-trait-image" width="300" height="300">
            <div v-if="isSelected" class="ape-trait__selected">
                <div class="ape-trait__selected-icon">
                    <ICheckmark width="24" />
                </div>
            </div>
        </div>
        <ApeTraitCounts :item="item" v-if="showCounts" />
        <div class="ape-trait_name">{{ name }}</div>
        <div class="ape-trait__info">
            <div class="ape-trait__id" v-if="showId">#{{ item.id }}</div>
            <div class="ape-trait__rank" v-if="showRank">RANK {{ item.rank }}</div>
        </div>
        <div v-if="isSelectionMode">
            <div class="ape-trait__counter" v-if="showCounter">
                <ACounter v-model:value="count" :max="totalBalance" />
            </div>
            <a
                class="ape-trait__button"
                href="#"
                @click.prevent="$emit('select')"
            >{{ (isSelected && canDeselect) ? 'De-select Trait' : 'Select Trait' }}</a>
        </div>
        <slot name="details" />
        <div class="ape-trait__rarity" :class="rarity">{{ rarityName }}</div>
    </div>
</template>
<script setup>
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity"
import { resizeImageCF } from "@/helpers/resize-image-cloudflare"
import { computed } from "vue-demi"
import ApeTraitCounts from "./ApeTraitCounts.vue"
import ACounter from "../ACounter.vue"
import ICheckmark from "@/assets/icons/checkmark.svg?inline"

const props = defineProps({
    item: {
        type: Object,
        default: () => ({})
    },
    showCounts: {
        type: Boolean,
        default: true
    },
    showCounter: {
        type: Boolean,
        default: true
    },
    showRank: {
        type: Boolean,
        default: true
    },
    showCategory: {
        type: Boolean,
        default: false
    },
    showId: {
        type: Boolean,
        default: true
    },
    canDeselect: {
        type: Boolean,
        default: true
    },
    isSelected: Boolean,
    isSelectionMode: Boolean,
    selectedCount: {
        type: Number,
        default: 1
    }
})
defineEmits(['select'])
const name = computed(() => props.item.name)
const image = computed(() => {
    const previewImage = props.item.image || props.item.rarityUnisexPreviewImageSrc ||
        props.item.rarityMalePreviewImageSrc ||
        props.item.rarityFemalePreviewImageSrc
    return resizeImageCF(previewImage, 512)
})

const rarity = computed(() => {
    if (props.item.rarity === ApeTraitRarity.og) return 'common'
    return props.item.rarity
})
const rarityName = computed(() => props.item.rarity ? props.item.rarity.charAt(0).toUpperCase() + props.item.rarity.slice(1) : '')
const totalBalance = computed(() => (props.item.offChain || 0) + (props.item.balance || 0))
const count = computed({
    get() {
        return props.selectedCount
    },
    set(val) {
        if (val > totalBalance.value || val < 1) return
        emit('select', val)
    }
})
</script>
<style lang="scss"></style>