<template>
  <div class="app-counter" :class="{ disabled }">
    <a
      href="#"
      class="app-counter-button"
      :class="{ disabled: minusDisabled }"
      @click.prevent="doMinus"
    >
      <IMinus width="21" />
    </a>
    <div class="app-counter-value">{{ value }}</div>
    <a
      href="#"
      class="app-counter-button"
      :class="{ disabled: plusDisabled }"
      @click.prevent="doPlus"
    >
      <IPlus width="20" />
    </a>
  </div>
</template>
<script setup>
import { computed, defineComponent } from "vue"
import IPlus from "@/assets/icons/plus.svg?inline"
import IMinus from "@/assets/icons/minus-stroke.svg?inline"
const props = defineProps({ value: Number, disabled: Boolean, max: Number });
const emit = defineEmits(['update:value']);
const valueModel = computed({
  get() {
    return props.value
  },
  set(val) {
    emitValue(val)
  }
})
const emitValue = (val) => {
  const nVal = parseInt(val)
  if (!nVal || nVal < 1) return
  if (props.max && nVal > props.max) {
    emit('update:value', props.max)
  }
  emit('update:value', nVal)
}
const plusDisabled = computed(() => props.max ? props.value === props.max : false)
const minusDisabled = computed(() => props.value <= 1)
const doMinus = () => {
  let newValue = props.value - 1
  if (newValue < 1) newValue = 1
  emit('update:value', newValue)
}
const doPlus = () => {
  const newValue = props.value + 1
  emit('update:value', newValue)
}
</script>
<style lang="scss">
.app-counter {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &-value {
    margin: 0 10px;
    line-height: 1;
    min-width: 20px;
    text-align: center;
  }
  &-button {
    color: #fff;
    user-select: none;
    display: block;
    font-size: 0;
    &:hover {
      color: var(--primary);
    }
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>