<template>
    <div class="trait-shop-top-actions">
        <div class="trait-shop-top-actions-back button-back" @click.prevent="goBack">
            <IArrowLeft width="32" />
            <span>Back</span>
        </div>
        <a href="#" class="trait-shop-top-action" @click.prevent="$emit('openCart')">
            <ICart width="18" />Cart
            <div
                v-if="cartItemsCount > 0"
                class="trait-shop-top-action-balance"
            >{{ cartItemsCount }}</div>
        </a>
        <!-- <a href="#" class="trait-shop-top-action">
            <IHeart width="20" />Wishlist
            <div class="trait-shop-top-action-balance">3</div>
        </a>-->
        <RouterLink :to="{ name: 'LootBox' }" class="trait-shop-top-action">
            <ILootBox width="20" />Lootbox
        </RouterLink>
    </div>
</template>
<script setup>
import ICart from "@/assets/icons/cart.svg?inline"
import IHeart from "@/assets/icons/heart.svg?inline"
import ILootBox from "@/assets/icons/lootbox.svg?inline"
import IArrowLeft from "@/assets/icons/arrow-left-long.svg?inline"
import { useRouter } from "vue-router"
import { computed } from "vue-demi"
import { useTraitShopCartStore } from "@/store/trait-shop/trait-shop-cart"
const router = useRouter()
const goBack = () => {
    const lastPath = router.options.history.state.back
    if (lastPath) {
        router.back()
    } else {
        router.push({ name: "Collection" })
    }
}
const cartStore = useTraitShopCartStore()
const cartItemsCount = computed(() => cartStore.itemsCount)
</script>
<style lang="scss">
.trait-shop-top-actions {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    &-back {
        margin-right: auto;
    }
    @media screen and (max-width: 420px) {
        flex-direction: column;
        align-items: flex-start;
        &-back {
            margin-bottom: 10px;
        }
    }
}
.trait-shop-top-action {
    box-sizing: border-box;
    padding: 10px 18px;
    margin-right: 10px;
    border: 1px solid var(--border);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
    position: relative;
    &-balance {
        position: absolute;
        top: 2px;
        left: 30px;
        background-color: var(--primary);
        color: #fff;
        font-size: 12px;
        border-radius: 50%;
        font-family: var(--font-montserrat);
        height: 16px;
        min-width: 16px;
        padding: 0 3px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    svg {
        margin-right: 10px;
    }
    &:last-child {
        margin-right: 0;
    }
    &:hover {
        background-color: var(--gray-light);
    }
    @media screen and (max-width: 420px) {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>