
<template>
  <div class="trait-shop-card-button">
    <crossmint-pay-button
      :collectionId.prop="collectionId"
      :projectId.prop="projectId"
      :mintConfig.prop="mintConfig"
      :environment.prop="environment"
      :mintTo.prop="walletAddress"
      class="trait-shop-card-button__btn"
    />
  </div>
</template>
<script>
import { useApesStorageApi } from '@/api/useApesStorageApi'
import { Config } from '@/config'
import { useWeb3Store } from '@/store/web3'
import CrossmintPayButton from '@crossmint/client-sdk-vanilla-ui'
import Web3 from "web3"

export default {
  name: 'CrossmintButton',
  props: {
    item: Object
  },
  data: () => ({
    info: {}
  }),
  components: {
    CrossmintPayButton
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const res = await useApesStorageApi({ toast: { error: true } }).exec({
        url: "/crossmint/buy-traits",
        params: {
          wallet: this.walletAddress,
          traits: [this.item.id],
          network: Config.network.name
        }
      })
      this.info = res
    },
  },

  computed: {
    projectId() {
      return Config.crossMint.projectId
    },
    collectionId() {
      return Config.crossMint.traitShop.collectionId
    },
    hasData() {
      return Object.keys(this.info).length > 0
    },
    environment() {
      return Config.network.name === 'mainnet' ? undefined : 'staging'
    },
    tokenId() {
      return this.item.out_id
    },
    contractAddress() {
      return this.item.contract_address
    },

    walletAddress() {
      return useWeb3Store().walletAddress
    },
    mintConfig() {
      // const conf = {
      //   "type": "erc-721",
      //   // I think need to change it somehow

      //   "totalPrice": price,
      //   item: [this.contractAddress, this.tokenId],
      //   "_mintId": 1,
      //   "timeOut": this.info.timeOut,
      //   "_price": this.info.tokenPrice,
      //   "signature": this.info.signature
      // }
      const conf2 = {
        "type": "erc-721",
        "totalPrice": this.info.priceETH + '',
        "traitsIds": this.info.traits,
        "amounts": this.info.amounts,
        "timeOut": this.info.timeOut,
        "_price": this.info.price,
        "signature": this.info.signature,
        "quantity": 1
      }
      return conf2
    }
  }
}
</script>

<style lang="scss">
.trait-shop-card-button {
  width: 100%;
  &__btn {
    &::part(button) {
      width: 100% !important;
    }
  }
}
</style>