<template>
    <div class="trait-shop-cart-item">
        <div class="trait-shop-cart-item__image">
            <img width="200" height="200" :src="image" alt="image">
        </div>
        <div class="trait-shop-cart-item__info">
            <div class="trait-shop-cart-item__name">
{{ name }}
</div>
            <div class="trait-shop-cart-item__price">
                <img width="16" src="@/assets/img/meth.png" alt="meth">
                {{ price }} METH
            </div>
            <div class="trait-shop-cart-item__counter">
                <ACounter v-model:value="countModel" />
            </div>
        </div>

        <a
            href="javascript:void(0)"
            class="trait-shop-cart-item__remove"
            @click.prevent="removeTrait"
        >
            <ITrash width="16" />
        </a>
    </div>
</template>
<script setup>
import { computed, ref } from "vue-demi";
import ACounter from "@/components/ACounter.vue";
import { useTraitShopCartStore } from "@/store/trait-shop/trait-shop-cart";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import ITrash from "@/assets/icons/trash.svg?inline"
import { formatNumberSpaces } from "@/helpers/format-number-spaces";
const props = defineProps({
    // {id, cnt}
    item: {
        type: Object,
        default: () => ({})
    }
})
const cartStore = useTraitShopCartStore()
const walletCollectionStore = useWalletCollectionStore()
const cartItem = computed(() => cartStore.items.find(item => item.id === props.item.id))

const image = computed(() => props.item.rarityUnisexPreviewImageSrc || props.item.rarityFemalePreviewImageSrc || props.item.rarityMalePreviewImageSrc)
const name = computed(() => props.item.name)
const price = computed(() => formatNumberSpaces(props.item.price))
// const leftCount = computed(() => maxQuantity.value < 0 ? Infinity : maxQuantity.value - mintedCount.value)

const mintedCount = computed(() => props.item.total_minted)
const maxQuantity = computed(() => props.item.maxQuantity)
const maxPerWallet = computed(() => props.item.maxPerWallet)
const leftCount = computed(() => maxQuantity.value < 0 ? Infinity : maxQuantity.value - mintedCount.value)

const maxCount = computed(() => maxPerWallet.value ? Math.min(leftCount.value, maxPerWallet.value) : leftCount.value)

const count = computed({
    get() {
        return cartItem.value?.cnt || 1
    },
    set(val) {
        cartStore.updateItemCnt({ id: props.item.id, cnt: val })
    }
})
const countModel = computed({
    get() {
        return count.value
    },
    set(val) {
        if (val > maxCount.value) return
        count.value = val
    }
})
const removeTrait = () => {
    cartStore.removeItem(props.item.id)
}
</script>
<style lang="scss">
.trait-shop-cart-item {
    margin-bottom: 10px;
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    &__name {
        margin-bottom: 5px;
    }
    &__image {
        width: 100px;
        margin-right: 10px;
        img {
            border-radius: 5px;
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
    &__info {
        margin-right: 10px;
    }
    &__price {
        display: flex;
        align-items: center;
        img {
            margin-right: 5px;
        }
    }
    &__counter {
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }
    &__remove {
        color: var(--red);
        border: 1px solid var(--red);
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-left: auto;
        flex-shrink: 0;
        transition: 0.2s;
        &:hover {
            background-color: var(--red);
            color: #fff;
        }
    }
    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }
}
</style>